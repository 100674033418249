import React, { useEffect, useState } from 'react'

import { useFormContext } from '../../context/form'
import { DeliveryType } from './Components/ScheduleModal/DeliveryType'
import { useTrip } from '../../context/trip'
import { Dropdown } from '../../components/Dropdown'
import { SubmitButton } from './Components/SubmitButton/SubmitButton'
import { cx } from '../../lib/classnames'
import { OrderList } from './Components/OrderList'
import { ScheduleModal } from './Components/ScheduleModal'
import { DistanceModalMobile } from '../../views/panel/Components/DistanceModalMobile/DistanceModalMobile'
import { Container, Footer, Header, Info } from './styles'
import MaskedInput from 'react-text-mask'
import { ref, set } from 'firebase/database'
import { database } from '../../services/firebase'
import { USERDATA } from '../../context/Constants'
import { User } from '../../types/protocols'
import styled from '@emotion/styled'
import History from '../History'

export default () => {
  const {
    createTrip,
    searching,
    makeScheduleTrip,
    motoboyNotFound,
    setMotoboyNotFound,
  } = useTrip()
  const [buttonIsEnabled, setButtonIsEnabled] = useState(true)
  const [userData, setUserData] = useState<User | null>(null)
  const [costCenterId, setCostCenterId] = useState('')
  const [costCenterOption, setCostCenterOption] = useState('')
  const currentUnixEpoch = Math.round(new Date().getTime() / 1000)
  const estimatedOnly = window.localStorage.getItem('estimatedOnly')

  function orderOn(tipo) {
    set(ref(database, 'pedidos/' + currentUnixEpoch), {
      cliente: costCenterOption,
      sem_entregador: true,
      telefone: userData.phone,
      data: new Date().toLocaleString('pt-BR'),
      tipo: tipo,
    })
  }

  const {
    state,
    options,
    resetForm,
    isDisabled,
    setDelivery,
    onChangeCostCenter,
    onChangeDirectProviderDocument,
    getFormatedData,
    second,
    setSecond,
    hasAddress,
    state: { distances, distance, price }
  } = useFormContext()

  const handleTrip = () => {
    if (buttonIsEnabled) {
      setButtonIsEnabled(false)

      const data = getFormatedData()

      if (data) createTrip(data, resetForm)

      setTimeout(() => {
        setButtonIsEnabled(true)
      }, 3000)
    } else {
      return
    }
  }

  const handleSchedule = async (
    scheduled_date: string,
    callback?: () => void
  ) => {
    const data = getFormatedData({ scheduled_date })
    if (data && scheduled_date) {
      makeScheduleTrip(data, {
        successCallback: resetForm,
        endCallback: callback,
      })
    }
  }

  const [isCancelCallModalOpen, setIsCancelCallModalOpen] = useState(false)

  const CancelCallModal = () => {
    return (
      <ContainerModal className={cx({ open: isCancelCallModalOpen })}>
        <div className="modal-cancel-trip">
          <h1 className="title is-4">Cancelar chamado</h1>
          <p className="subtitle is-6">
            Tem certeza que deseja cancelar este chamado?
          </p>
          <p>
            A Vaay leva cerca de 60 segundos para encontrar um entregador para
            você. <br /> Se você cancelar agora, o chamado será cancelado e você
            terá que fazer um novo pedido.
          </p>
          <hr />
          <div className="btn-group">
            <button
              className="button is-danger"
              style={{
                width: '100%',
              }}
              onClick={() => {
                orderOn('Pedido Cancelado')
                handleTrip()
                setIsCancelCallModalOpen(false)
              }}
            >
              Cancelar chamado
            </button>
            <button
              className="button is-primary"
              style={{
                width: '100%',
              }}
              onClick={() => {
                setIsCancelCallModalOpen(false)
              }}
            >
              Continuar buscando
            </button>
          </div>
        </div>
      </ContainerModal>
    )
  }

  const ContainerModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    background: white;
    padding: 1rem;
    transform: translate(-50%, -50%);
    display: none;
    &.open {
      display: block;
    }
  `

  useEffect(() => {
    const user = window.sessionStorage.getItem(USERDATA)
    if (user) {
      setUserData(JSON.parse(user))
    }
  }, [])

  useEffect(
    () => {
      const id = options.find(option => option.id === costCenterId)
      if (id) {
        setCostCenterOption(id.label)
      }
    },
    [costCenterId]
  )

  const noCostCenter = !Boolean(state.cost_center_id)
  return (
    <>
      <Container>
        <Header>
          <div
            onClick={() => {
              setSecond(0)
            }}
          >
            {/* {userData && <strong>{userData.company_object.full_name}</strong>} */}
            {/* <p style={{ color: 'white' }}>Reload in 180s: {second}</p> */}
            <Dropdown
              label={`Selecione a origem: `}
              onChange={e => {
                onChangeCostCenter(e)
                setCostCenterId(e)
              }}
              value={state.cost_center_id}
              options={options}
            />
          </div>
        </Header>
        <Footer>
          <span style={{ gridArea: 'type' }}>
            <DeliveryType
              value={state.pack_type_id}
              updateCallback={setDelivery}
            />
          </span>
          {
            estimatedOnly ? (
              <></>
            ) : (
              <span style={{ gridArea: 'schedule' }}>
                <ScheduleModal disabled={isDisabled} action={handleSchedule} />
              </span>
            )
          }
          <span style={{ gridArea: 'submit' }}>
            {
              estimatedOnly ? (
                <SubmitButton
                  className={cx({ loading: searching })}
                  disabled={!hasAddress || !price}
                  onClick={() => {
                    if (searching) {
                      setIsCancelCallModalOpen(true)
                    } else {
                      handleTrip()
                      orderOn('Pedido Solicitado')
                    }
                    setSecond(0)
                  }}
                >
                  {!searching ? 'Solicitar' : 'Cancelar'}
                </SubmitButton>
              ) : (
                <SubmitButton
                  className={cx({ loading: searching })}
                  disabled={isDisabled}
                  onClick={() => {
                    if (searching) {
                      setIsCancelCallModalOpen(true)
                    } else {
                      handleTrip()
                      orderOn('Pedido Solicitado')
                    }
                    setSecond(0)
                  }}
                >
                  {!searching ? 'Solicitar' : 'Cancelar'}
                </SubmitButton>
              )
            }
          </span>
        </Footer>
        <History type={'em_andamento'} />
        {
          estimatedOnly ? (
            <></>
          ) : (
            <div style={{ borderBottom: 'solid 1px #FFFFFF33', paddingBottom: 15 }}>
              <div style={{ color: '#FFF', marginBottom: 10, fontSize: 13 }}>
                Direcionar para:&nbsp;
                <span style={{ fontWeight: 'bold', fontSize: 11 }}>
                  {state.direct_provider
                    ? state.direct_provider.first_name +
                    ' ' +
                    state.direct_provider.last_name
                    : state.direct_provider_loading
                      ? 'Localizando entregador...'
                      : null}
                </span>
                {state.direct_provider_document.length > 0 &&
                  !state.direct_provider &&
                  !state.direct_provider_loading ? (
                  <span style={{ color: '#F55', marginTop: 10, fontSize: 11 }}>
                    Nenhum entregador localizado com esse CPF
                  </span>
                ) : null}
              </div>
              <MaskedInput
                mask={[
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '.',
                  /\d/,
                  /\d/,
                  /\d/,
                  '-',
                  /\d/,
                  /\d/,
                ]}
                className="input-text"
                value={state.direct_provider_document}
                onChange={onChangeDirectProviderDocument}
                placeholder="CPF do entregador"
              />
            </div>
          )
        }
        <div className={'desktop-hidden'}>
          <DistanceModalMobile />
        </div>
        {
          estimatedOnly ? (<></>) : (
            <Info>Preencher os campos abaixo é opcional!</Info>
          )
        }
        <OrderList />
      </Container>
      <CancelCallModal />
    </>
  )
}

