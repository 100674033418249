import React, { useCallback } from 'react'
import { Redirect } from 'react-router-dom'
import { useInput } from '../../lib/hooks'

import { TextInput } from './Input'

import { isDevelopment } from '../../context/Constants'
import { useAuth } from '../../context/auth'
import {
  Container,
  Banner,
  Form,
  FormContent,
  ImageContainer,
  Button,
} from './styles'
import { Loading } from '../../components/Loading'

export default () => {
  const [email, setEmail] = useInput(() => {
    return isDevelopment ? 'admin@vaay.com' : ''
  })

  const [password, setPassword] = useInput(() => {
    return isDevelopment ? 'senha' : ''
  })

  const {
    login,
    state: { loading, user },
  } = useAuth()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<Element>) => {
      e.preventDefault()
      login(email, password)
    },
    [name, password]
  )

  return user ? (
    <Redirect to="/" />
  ) : (
    <Container>
      <Banner>
        <img
          src="/banner.jpg"
          alt="banner"
          //   style={{ width: '100%', height: '100%', objectFit: 'cover' }}
        />
      </Banner>
      <Form onSubmit={handleSubmit}>
        <ImageContainer>
          <img
            src="https://api-temp.vercel.app/vaay/logo_white.png"
            alt="logo"
          />
          <p className="subtitle is-5">Entregas Express</p>
          <p className="subtitle">1.3.1</p>
        </ImageContainer>
        <FormContent>
          <h1 className="title is-4" style={{ color: '#fff' }}>
            Bem-vindo!
          </h1>
          <TextInput
            required
            type="email"
            value={email}
            placeholder="Email"
            onChange={setEmail}
            autoComplete="email"
          />
          <TextInput
            required
            label="Password"
            type="password"
            value={password}
            onChange={setPassword}
            autoComplete="current-password"
          />
          {/* <p className="subtitle is-6">
					<a href="#">Esqueceu sua senha?</a>
				</p> */}
          <Button>{loading ? <Loading /> : 'Entrar'}</Button>
        </FormContent>
      </Form>
    </Container>
  )
}

