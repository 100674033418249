import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useContext,
  useRef,
} from 'react'
import { ResponseProviderLocation } from '../responses.type'
import { useAuth } from '../auth'
import { client } from '../Client'
import { Suggestion } from '../places'
import { getLocation } from '../../lib/getLocation'
import { formatPhone, formatPrice, toNumber } from '../../lib/masks'
import { toast } from 'react-toastify'
import { useDebounceCallback, useDebounceCallbackCheck } from '../../lib/hooks'
import Map from '../../lib/Map'
import produce from 'immer'

import {
  FormValues,
  FormState,
  ConfirmationType,
  PaymentType,
} from './form.types'
import {
  FaTimesCircle,
  FaFileSignature,
  FaQrcode,
  FaMoneyBillWaveAlt,
  FaCreditCard,
} from 'react-icons/fa'
import { Console } from 'console'

interface Option {
  id: string
  label: string
}

const initialValue = {
  coordinates: [0, 0],
  address_complement: '',
  confirmationType: ConfirmationType.NONE,
  paymentType: PaymentType.CARD,
  destiny: '',
  description: '',
  name: '',
  phone: '',
} as FormValues

const initialState: FormState = {
  price: null,
  distance: '',
  distances: [],
  cost_centers: [],
  cost_center: 'Escolha a unidade',
  cost_center_id: '',
  direct_provider_document: '',
  direct_provider: false,
  direct_provider_loading: false,
  cost_center_location: null,
  pack_type_id: '5c48c5ded8f8a95a31425b23',
  values: [initialValue],
}

interface Context {
  state: FormState
  options: Option[]
  isDisabled: boolean
  disabledInput: boolean
  setDelivery(id: string): void
  onChange(idx: number, name: string): (e: React.ChangeEvent<any>) => void
  onChangeDirectProviderDocument(): (e: React.ChangeEvent<any>) => void
  addValue(): void
  removeValue(idx: number): () => void
  resetForm(): void
  onSelectType(idx: number, name: keyof FormValues): (e: string) => void
  onSelectAddress(idx: number): (address: Suggestion) => Promise<void>
  onChangeCostCenter(id: string): void
  onChangeAddress(
    idx: number
  ): (e: any, target: { newValue: string; method: string }) => void
  getFormatedData(data?: any): any
  second: number
  setSecond: React.Dispatch<React.SetStateAction<number>>
  hasAddress: boolean
  setHasAddress: React.Dispatch<React.SetStateAction<boolean>>
}

export const FormContext = React.createContext({} as Context)

export function useFormContext() {
  const context = useContext(FormContext)

  if (context === undefined) {
    throw new Error(
      'HistoryContext can only be used within <HistotyProvider />'
    )
  }
  return context
}

export const FormProvider: React.SFC = (props: any) => {
  const {
    state: { costCenters, user },
  } = useAuth()

  const [state, setState] = useState<FormState>(initialState)

  const options = useMemo(
    () =>
      costCenters.map(cost => ({
        id: cost._id,
        label: cost.name,
      })),
    [costCenters]
  )

  const [disabledInput, setDisabledInput] = useState(true)
  const [isDisabled, setDisabled] = useState(true)
  const [hasAddress, setHasAddress] = useState(false)

  useDebounceCallback(state, 800, state => {
    const hasValues = state.values.every(({ name, destiny }) => {
      return name.length >= 3 && destiny !== ''
    })

    const hasPackType = Boolean(state.pack_type_id)
    const hasCostCenter = Boolean(state.cost_center_id)
    const hasCostCenterLocation = Boolean(state.cost_center_location)

    const estimatedOnly = window.localStorage.getItem('estimatedOnly')
    if (!estimatedOnly) {
      setDisabled(!(hasPackType && hasCostCenter && hasCostCenterLocation)) 
    }
    setDisabledInput(!(hasCostCenter && hasCostCenterLocation))
  })

  const onChangeDirectProviderDocument = useCallback(
    async (e: React.ChangeEvent<any>) => {
      const value = e.target.value
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace('-', '')
      setState(prev => ({
        ...prev,
        direct_provider_document: value,
        direct_provider: false,
        direct_provider_loading: true,
      }))

      let res = await client.get<any>(`delivery/v1.1/find_by_document/${value}`)
      if (res.success && res.data.length > 0) {
        setState(prev => ({ ...prev, direct_provider: res.data[0]! }))
      }
      setState(prev => ({ ...prev, direct_provider_loading: false }))
    },
    []
  )

  const onChangeCostCenter = useCallback(
    (id: string) => {
      const { name, _id, location } = costCenters.find(c => c._id === id)!
      setState(prev => ({
        ...prev,
        cost_center_id: id,
        cost_center_location: location,
      }))
      Map.setOrigin({ lat: location[0], lng: location[1] })
      displayProviderLocation(location, state.pack_type_id)
      setInterval(() => {
        displayProviderLocation(location, state.pack_type_id)
      }, 15000)
    },
    [costCenters]
  )

  const setDelivery = useCallback(
    (id: string) => {
      if (state.cost_center_location) {
        displayProviderLocation(state.cost_center_location, state.pack_type_id)
      } else {
        toast.error('Selecione um local de origem')
        return
      }
      setState(prev => ({ ...prev, pack_type_id: id }))

      client
        .post<any>('delivery/v1.1/estimate', {
          company_cost_center_id: state.cost_center_id,
          pack_type_id: id,
          destinations: state.values.map(value => {
            return {
              type: 'Point',
              coordinates:
                value.coordinates[0] != 0
                  ? value.coordinates
                  : [
                      state.cost_center_location![0],
                      state.cost_center_location![1],
                    ],
              address: value.destiny,
              description: value.description,
              user_name: value.name,
              user_phone: value.phone,
              address_complement: value.address_complement,
              invoice: {
                payment_type: value.paymentType,
                price: toNumber(value.price),
                ...(value.paymentType === PaymentType.CASH &&
                  value.money_change !== '' && {
                    money_change: toNumber(value.money_change),
                  }),
              },
            }
          }),
        })
        .then(resp => {
          if (resp.success) {
            setState(state =>
              produce(state, draft => {
                draft.price = resp.data.value
                draft.distance = resp.data.distance
              })
            )
          }
        })
    },
    [state]
  )

  // add a new empty destination value
  const addValue = useCallback(() => {
    setState(prevState =>
      produce(prevState, draft => {
        draft.values.push(initialValue)
      })
    )
  }, [])

  // remove destination
  const removeValue = useCallback(
    (idx: number) => () => {
      setState(prevState =>
        produce(prevState, draft => {
          draft.values.splice(idx, 1)
        })
      )

      if (user && user.pack_rules) {
        const { pack_rules } = user
        Map.removeWayPoint(idx)
        Map.getDistanceCallback = (distanceText, distanceValue) => {
          setState(state =>
            produce(state, draft => {
              // draft.price = calculatePrice(draft.values.length, distanceValue, pack_rules)
              draft.distance = distanceText
            })
          )
        }
      }
    },
    []
  )

  const onChange = useCallback(
    (idx: number, name: string) => (e: React.ChangeEvent<any>) => {
      const value = e.target.value

      setState(prevState =>
        produce(prevState, draft => {
          switch (name) {
            case 'phone':
              draft.values[idx].phone = formatPhone(value)
              break
            case 'price':
            case 'width':
            case 'length':
            case 'height':
            case 'money_change':
              draft.values[idx][name] = formatPrice(value)
              break
            default:
              draft.values[idx][name] = value
          }
        })
      )
    },
    []
  )

  const onChangeAddress = useCallback(
    (idx: number) => (e: any, target: { newValue: string; method: string }) => {
      setState(state =>
        produce(state, draft => {
          draft.values[idx].destiny = target.newValue
          setHasAddress(true)
        })
      )
    },
    []
  )

  const onSelectAddress = useCallback(
    (idx: number) => async (address: Suggestion) => {
      try {
        const location = await getLocation({ placeId: address.id })

        setState(state =>
          produce(state, draft => {
            draft.values[idx].coordinates = [location.lat, location.lng]
          })
        )

        if (user && user.pack_rules) {
          const { pack_rules } = user
          Map.addWayPoint(location, idx)
          Map.getDistanceCallback = (distanceText, distanceValue) => {
            // km to meters
            const value = distanceValue / 1000
            setState(state =>
              produce(state, draft => {
                // draft.price = calculatePrice(draft.values.length, value, pack_rules)
                draft.distance = distanceText
              })
            )
          }
        }
      } catch (err) {
        console.log(err)
        toast.error(err.message)
      }
    },
    [user]
  )

  const onSelectType = useCallback(
    (idx: number, name: keyof FormValues) => (value: any) => {
      setState(prev =>
        produce(prev, draft => {
          switch (name) {
            case 'confirmationType':
              draft.values[idx].confirmationType = value
              break
            case 'paymentType':
              draft.values[idx].paymentType = value
              break
            default:
              break
          }
        })
      )
    },
    []
  )

  const resetForm = useCallback(() => {
    Map.removeAllPoints()
    setState(prevState => ({
      ...initialState,
      cost_center_id: prevState.cost_center_id,
      cost_center_location: prevState.cost_center_location,
    }))
  }, [])

  const getFormatedData = useCallback(
    (data?: any) => {
      if (user) {
        return {
          ...(Boolean(data) && data),
          name: user.display_name,
          email: user.email,
          phone: user.phone,
          company_id: user.company,
          cost_center_id: state.cost_center_id,
          direct_provider_id: state.direct_provider
            ? state.direct_provider._id
            : false,
          company_cost_center_id: state.cost_center_id,
          sourceLocation: state.cost_center_location,
          type_id: state.pack_type_id,
          pack_type_id: state.pack_type_id,
          price: Number(state.price) * 100,
          destinations: state.values.map(value => {
            return {
              type: 'Point',
              coordinates:
                value.coordinates[0] != 0
                  ? value.coordinates
                  : state.cost_center_location,
              address: value.destiny ? value.destiny : 'Não informado',
              description: value.description,
              user_name: value.name ? value.name : 'Não informado',
              user_phone: value.phone ? value.phone : '(99) 99999-9999',
              address_complement: value.address_complement,
              invoice: {
                payment_type: value.paymentType,
                price: toNumber(
                  value.price ? value.price.replace(/\./g, '') : '0'
                ),
                ...(value.paymentType === PaymentType.CASH && {
                  money_change: toNumber(
                    value.money_change
                      ? value.money_change.replace(/\./g, '')
                      : '0'
                  ),
                }),
              },
            }
          }),
        }
      }

      return null
    },
    [user, state]
  )

  const [second, setSecond] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      setSecond(second => second + 1)
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  useEffect(
    () => {
      if (second === 500) {
        window.location.reload()
      }
    },
    [second]
  )

  return (
    <FormContext.Provider
      value={{
        state,
        setDelivery,
        onChange,
        onChangeDirectProviderDocument,
        isDisabled,
        disabledInput,
        addValue,
        removeValue,
        options,
        resetForm,
        onSelectType,
        onSelectAddress,
        onChangeCostCenter,
        onChangeAddress,
        getFormatedData,
        second,
        setSecond,
        hasAddress,
        setHasAddress,
      }}
      {...props}
    />
  )
}

export async function displayProviderLocation(
  sourceLocation: any,
  pack_type_id: any
) {
  try {
    console.log('TRY displayProviderLocation', { sourceLocation, pack_type_id })
    const response = await client.post<ResponseProviderLocation>(
      'delivery/v1.1/trip/providers/map',
      { sourceLocation, pack_type_id }
    )

    if (response.success) {
      const { providers } = response.data

      const locations = providers.map(provider => {
        const [lat, lng] = provider.providerLocation
        return { lat, lng }
      })

      console.log(
        'SUCCESS: delivery/v1.1/trip/providers/map',
        {
          sourceLocation,
          pack_type_id,
        },
        response
      )

      Map.showProviderLocation(locations)
    } else {
      console.log('ELSE: displayProviderLocation')
    }
  } catch (err) {
    console.log(err)
    console.log('ERROR: delivery/v1.1/trip/providers/map', {
      sourceLocation,
      pack_type_id,
    })
  }
}

export const ConfirmationTypeOptions = [
  {
    label: 'Não Precisa',
    type: ConfirmationType.NONE,
    icon: FaTimesCircle,
  },
  {
    label: 'Assinatura',
    type: ConfirmationType.SIGNATURE,
    icon: FaFileSignature,
  },
  {
    label: 'QRCode',
    type: ConfirmationType.QRCODE,
    icon: FaQrcode,
  },
]

export const PaymentTypeOptions = [
  {
    label: 'Online',
    type: PaymentType.CARD,
    icon: FaTimesCircle,
  },
  {
    label: 'Dinheiro',
    type: PaymentType.CASH,
    icon: FaMoneyBillWaveAlt,
  },
  {
    label: 'Crédito',
    type: PaymentType.FACE_CREDIT,
    icon: FaCreditCard,
  },
  {
    label: 'Debito',
    type: PaymentType.FACE_DEBIT,
    icon: FaCreditCard,
  },
]
